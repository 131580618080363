export const firebaseConfig = {
    apiKey: "AIzaSyC06nMCs_JMxj3TKc6c_jKa7A4OltCAfsM",
    authDomain: "apotek1-dna.firebaseapp.com",
    projectId: "apotek1-dna",
    storageBucket: "apotek1-dna.appspot.com",
    messagingSenderId: "345725891061",
    appId: "1:345725891061:web:1def810231675f15fc30bc",
    measurementId: "G-0NLFB327XW",
    databaseURL: "https://apotek1-dna-default-rtdb.europe-west1.firebasedatabase.app/",
};
