import { getFirestore, deleteDoc, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";

type dbUser = {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    unit: string;
};

type dbUnit = {
    // id: string;
    name: string;
    region: string;
};

type dbRegion = {
    // id: string;
    name: string;
};

const deleteItem = (itemPath: string, itemId: string) => {
    const db = getFirestore();
    if (!itemId) {
        throw Error("no id set for delete");
    }
    return deleteDoc(doc(db, itemPath, itemId));
};

export const deleteUnit = (itemId: string) => {
    return deleteItem("units", itemId);
};

export const deleteUser = (itemId: string) => {
    return deleteItem("users", itemId);
};

export const deleteRegion = (itemId: string) => {
    return deleteItem("regions", itemId);
};

export const deleteImage = (itemId: string) => {
    return deleteItem("images", itemId);
};

const createItem = (itemPath, item: any) => {
    const db = getFirestore();
    const id = uuidv4();
    return setDoc(doc(db, itemPath, id), item);
};

const createItemWithID = (itemId, itemPath, item: any) => {
    const db = getFirestore();
    if (itemId) {
        return setDoc(doc(db, itemPath, itemId), item);
    } else {
        throw Error("No ID set");
    }
};

const overwriteItem = (itemPath, itemId, item: any) => {
    const db = getFirestore();
    if (!itemId) {
        throw Error("No ID set");
    } else {
        return setDoc(doc(db, itemPath, itemId), item);
    }
};

export const overwriteUser = (itemId: string, item: dbUser) => {
    return overwriteItem("users", itemId, item);
};

export const overwriteUnit = (itemId: string, item: dbUnit) => {
    console.log("oppdaterer db", itemId, item);
    return overwriteItem("units", itemId, item);
};

export const overwriteRegion = (itemId: string, item: dbRegion) => {
    return overwriteItem("region", itemId, item);
};

export const createUser = (user: dbUser) => {
    console.log({ user });
    return createItemWithID(user.id, "users", user);
};

export const createRegion = (region: dbRegion) => {
    return createItem("region", region);
};

export const createUnit = (unit: dbUnit) => {
    return createItem("units", unit);
};

export const promoteUserToAdmin = (userId: string) => {
    return getAuth()
        .currentUser.getIdToken()
        .then((token) => {
            if (token) {
                return fetch("https://setadminonuser-o47vzlec7q-uc.a.run.app", {
                    method: "POST",
                    body: JSON.stringify({
                        userId: userId,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        authorization: "Bearer " + token,
                    },
                    //mode: "no-cors"
                });
            }
        });
};

// http://127.0.0.1:5001/apotek1-dna/us-central1/testLookup

export const testLookup = (userId: string) => {
    return getAuth()
        .currentUser.getIdToken()
        .then((token) => {
            if (token) {
                return fetch("http://127.0.0.1:5001/apotek1-dna/us-central1/testLookup", {
                    method: "POST",
                    body: JSON.stringify({
                        phoneNumber: userId,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        authorization: "Bearer " + token,
                    },
                    //mode: "no-cors"
                });
            }
        });
};
