import { FunctionComponent, PropsWithChildren } from "react";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./firebaseConfig";
import { FirebaseService } from "./FirebaseService";

export const FirebaseWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseService>{children}</FirebaseService>
        </FirebaseAppProvider>
    );
};
