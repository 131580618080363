import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import * as Sentry from "@sentry/react";
import { FirebaseService } from "./components/FirebaseService";
import App from "./App";

Sentry.init({
    dsn: "https://997c7b716a465d8ff2fe542af4fc37f3@o4506729993732096.ingest.sentry.io/4506729995501568",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    beforeSend: (event) => {
        if (window.location.hostname === "localhost") {
            return null;
        }
        return event;
    },
    environment: window.location.hostname === "localhost" ? "development" : "production",
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        /^https:\/\/yourserver\.io\/api/,
        /^https:\/\/securetoken\.googleapis\.com/,
        "medarbeiderdna.no",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <FirebaseService>
            <App />
        </FirebaseService>
    </React.StrictMode>,
);
