import { collection, getFirestore, orderBy, limit, query, doc, setDoc, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { ref, getDatabase, remove } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { useEffect, useState } from "react";
import { transformUrlToBunny } from "../../display/Screenview.tsx";

const useImages = ({ resultsLimit }: { resultsLimit?: number }) => {
    const db = getFirestore();

    const imagesRef = collection(db, "/images");
    const q = query(imagesRef, orderBy("timestamp", "desc"), limit(resultsLimit ? resultsLimit : 500));

    return useCollection(q);
};

export const useRealTimeImages = () => {
    const database = getDatabase();
    const [snapshots, loading, error] = useList(ref(database, "images"));
    return { snapshots, loading, error };
};

export const deleteRealtimeImage = (imgRef) => {
    const database = getDatabase();
    if (imgRef !== "" && imgRef !== undefined && imgRef) {
        //const imgRef =ref(database, 'images/'+itemId)
        alert("remove " + imgRef.toString());

        remove(imgRef);
    }
};

export const useUserImages = ({ userId }: { userId: string }) => {
    const db = getFirestore();

    const imagesRef = collection(db, "/images");
    const q = query(imagesRef, where("userId", "==", userId), limit(500));

    return useCollection(q);
};

export const hideImage = (docID) => {
    const db = getFirestore();
    if (!!docID && docID.length > 10) {
        const imageRef = doc(db, "images", docID);
        console.log(imageRef);
        console.log(docID);
        setDoc(imageRef, { hidden: true }, { merge: true });
    }
};

export const unHideImage = (docID) => {
    const db = getFirestore();
    if (!!docID && docID.length > 10) {
        const imageRef = doc(db, "images", docID);
        console.log(imageRef);
        console.log(docID);
        setDoc(imageRef, { hidden: false }, { merge: true });
    }
};

export const useUserLatestImage = (userId) => {
    const [image, setImage] = useState<string | undefined>(undefined);
    const [userImagesDB] = useUserImages({ userId: userId });

    useEffect(() => {
        const images: string[] = [];
        userImagesDB?.docs.map((doc) => {
            images.push(transformUrlToBunny(doc.data().imageUrl));
        });
        setImage(images ? images[0] : undefined);
    }, [userImagesDB, userId]);

    return image;
};

export default useImages;
