import { useState } from "react";
import DNAMerke from "../assets/DNA_merke.png?url";
import menuBurger from "../assets/menuBurger.svg?url";
import menuX from "../assets/menuX.svg?url";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore, limit, orderBy, query } from "firebase/firestore";

const Menu = () => {
    const navigate = useNavigate();
    const firebaseApp = useFirebaseApp();

    const db = getFirestore();
    const auth = getAuth(firebaseApp);
    const pledgesRef = collection(db, `/pledges/${auth.currentUser?.uid}/version`);
    const pledgeQuery = query(pledgesRef, orderBy("timestamp", "desc"), limit(25));
    const [pledgesData, isLoadingPledges, pledgesError] = useCollection(pledgeQuery);


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const Logout = () => (
        <button
            onClick={() => {
                auth.signOut()
                    .then(() => {
                        navigate("/");
                        setIsMenuOpen(false);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }}
            className={"rounded-full bg-white text-brand-midGreen absolute  bottom-20 w-auto px-10 py-2 mt-10 "}
        >
            Logg ut
        </button>
    );
    return (
        <div
            style={{ zIndex: 100000 }}
            className={"overflow-clip absolute top-0 left-0 h-[100dvh] w-screen pointer-events-none"}
        >
            <div
                //onClick={() => setIsMenuOpen(!isMenuOpen)}
                style={{
                    zIndex: 1000001,
                    transform: isMenuOpen ? "" : `translate3d(100vw,0,0)`,
                    transition: "transform 0.7s cubic-bezier(0.22, 1, 0.36, 1)",
                }}
                className="bg-brand-darkGreen pointer-events-auto w-screen h-[100dvh] absolute top-0 left-0 flex flex-col items-center justify-center gap-6 text-xl"
            >
                <button className={"absolute top-8 right-6 p-6"} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <img src={menuX} />
                </button>

                <Link
                    onClick={() => {
                        setIsMenuOpen(false);
                    }}
                    to={"/"}
                >
                    Hjem
                </Link>
                {!isLoadingPledges &&
                    !pledgesError &&
                    (!!pledgesData?.docs.length ? (

                            <Link
                                onClick={() => {
                                    setIsMenuOpen(false);
                                }}
                                to={"/register"}
                            >
                                Ta ny selfie
                            </Link>

                    ) : (


                            <Link
                                onClick={() => {
                                    setIsMenuOpen(false);
                                }}
                                to={"/selfie"}
                            >
                                Ta ny selfie
                            </Link>

                        )
                    )}


                <Link
                    onClick={() => {
                        setIsMenuOpen(false);
                    }}
                    to={"/dna"}
                >
                    DNA
                </Link>

                <Logout />
            </div>
            <menu className={"absolute top-0 left-0 w-full pointer-events-none grid grid-cols-2 mt-10 px-8"}>
                <div className={"mr-auto w-1/2"}>
                    <img className={"max-w-[15vh] w-full"} src={DNAMerke} />
                </div>
                <button className="ml-auto h-min pointer-events-auto" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <img src={menuBurger} />
                </button>
            </menu>
        </div>
    );
};

export default Menu;
