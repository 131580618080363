import { utils, writeFile } from "xlsx";
import { Button } from "../../../components/Button";

import { useAreas } from "./useAreas.ts";
import { useUnits } from "./useUnits.ts";
import { useSWRUsers } from "../../../hooks/admin/useUsers";
import { useRegions } from "./useRegions";
import { useRegisteredUsers } from "./useRegisteredUsers";
import { collection, getFirestore, limit, orderBy, query } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";

const DownloadUsersExcel = ({ users }: { users: Array<string> }) => {
    const db = getFirestore();
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);


    const { usersById } = useSWRUsers();
    const { usersByPhoneNumber } = useRegisteredUsers();
    const { areas } = useAreas();
    const { units } = useUnits();
    const { regions } = useRegions();
    
    console.log("usersbyid", { usersById });

    if (
        !Object.keys(usersByPhoneNumber).length ||
        !Object.keys(areas).length ||
        !Object.keys(units).length ||
        !Object.keys(regions).length ||
        !usersById ||
        !Object.keys(usersById).length
    ) {
        return null;
    }

    const formattedUsers = users?.map((user) => {

        console.log({user})
        const dbUser = usersById[user];
        const registeredUser = usersByPhoneNumber[user];
        const unit = units[dbUser?.unit];
        const pledgesRef = collection(db, `/pledges/${auth.currentUser?.uid}/version`);
        const pledgeQuery = query(pledgesRef, orderBy("timestamp", "desc"), limit(25));

        const [pledgesData, isLoadingPledges, pledgesError] = useCollection(pledgeQuery);

        console.log(pledgesData?.docs);
        return {
            Fornavn: dbUser?.firstName,
            Etternavn: dbUser?.lastName,
            Telefonnummer: "+" + dbUser?.id,
            Enhet: unit?.name,
            Region: regions[dbUser?._row.region],
            Lokasjon: dbUser?._row.location,
            // TODO Get the area for a user
            // Område: areas ? areas[dbUser.area] : "",
            Ansattform: (dbUser?._row && dbUser?._row["Ansattform på hovedarbeidsforhold"]) || "N/A",
            "Er registrert": registeredUser?.metadata.creationTime ? "J" : "N",
            Registreringstidspunkt: registeredUser?.metadata.creationTime,
        };
    });

    const createAndDownload = () => {
        /* generate worksheet and workbook */
        const worksheet = utils.json_to_sheet(formattedUsers);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "ansatte");

        /* fix headers */
        utils.sheet_add_aoa(worksheet, [[]], { origin: "A1" });

        /* calculate column width */
        //const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        // worksheet["!cols"] = [ { wch: max_width } ];

        /* create an XLSX file and try to save to Presidents.xlsx */
        writeFile(workbook, new Date(Date.now()).toISOString() + "-medarbeiderdna.xlsx", { compression: true });
    };

    return (
        <>
            <Button className="bg-green-500  text-white hover:bg-green-600 h-min" onClick={createAndDownload}>
                Eksporter til excel
            </Button>
        </>
    );
};

export default DownloadUsersExcel;
