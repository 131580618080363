import { collection, doc, getFirestore, limit, query } from "firebase/firestore";
import { useCollection } from "@tatsuokaniwa/swr-firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useUserImages } from "./useImages";
import { transformUrlToBunny } from "../../display/Screenview";
import { useRegisteredUserByPhoneNumber } from "../../display/admin/components/useRegisteredUsers";

const useUsers = ({ resultsLimit }: { resultsLimit?: number }) => {
    const db = getFirestore();

    const usersRef = collection(db, "/users");
    const q = query(usersRef, limit(resultsLimit ? resultsLimit : 500));

    return useCollection(q);
};

export const useUser = ({ userPhoneNumber }: { userPhoneNumber: string }) => {
    const db = getFirestore();
    // [value, loading, error]
    return useDocument(doc(db, "users", userPhoneNumber || ""), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });
};

export const useProfileImage = ({ userId }: { userId: string }) => {
    const registeredUser = useRegisteredUserByPhoneNumber(userId);
    const [userImagesDB] = useUserImages({ userId: registeredUser.uid });

    if (!userImagesDB) {
        return undefined;
    }

    const images: string[] = userImagesDB.docs.map((doc) => transformUrlToBunny(doc.data().imageUrl));
    return images[0] || undefined;
};

export default useUsers;

interface UserRecord {
    _row: {
        firstName: string;
        "Enhet eier": string;
        lastName: string;
        unit: string;
        _region: string;
        phoneNumber: string;
        Enhet: string;
        "Last Name": string;
        "Fullt navn": string;
        location: string;
        "First Name": string;
        "Ansattform på hovedarbeidsforhold": string;
        Mobile: string;
        _unit: string;
        region: string;
        Location: string;
    };
    lastName: string;
    firstName: string;
    unit: string;
    id: string;
}

export const useSWRUsers = () => {
    const { data: users, ...rest } = useCollection<UserRecord>({
        path: "users",
    });

    return {
        users,
        usersById: users?.reduce<Record<string, UserRecord>>(function (map, obj) {
            map[obj.id] = obj;
            return map;
        }, {}),
        ...rest,
    };
};
