import { Link } from "react-router-dom";
import { useRegisteredUsers, useUsersPerUnit } from "./components/useRegisteredUsers";
import { useRegions } from "./components/useRegions";
import { memo, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AxisOptions, Chart } from "react-charts";
import { ErrorBoundary } from "react-error-boundary";
import { LoadingApotekData } from "./LoadingApotekData";
import { useSWRUsers } from "../../hooks/admin/useUsers";
import { useUnits, useUnitsByRegion } from "./components/useUnits";
import { AuthUser, getDates } from "./AdminDefaultView";
import { BlockingData } from "swr/_internal";
import { DocumentData } from "@tatsuokaniwa/swr-firestore";
import { isEmpty } from "lodash";
import Toggle from "./components/Toggle";
import { Cancel, DropdownRegion, Modal, Save } from "./UnitView";
import { Button } from "./UnitsView";
import { createRegion } from "./lib/db";

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

interface RegionTableProps {
    regions?: unknown[];
    progressPending?: boolean;
    usersPerRegion?: any;
    button?: () => JSX.Element;
}

export function useUserPrDayLastNDays<Data, Config>(
    authUsers: BlockingData<Data, Config> extends true ? Data : Data | undefined,
    usersById: {},
    ignore,
    dbUsers:
        | DocumentData<{
              firstName: string;
              lastName: string;
              region: string;
              unit: string;
              id: string;
          }>[]
        | string
        | number
        | boolean
        | Object,
    dateFrom: Date,
    dateTo: Date,
    regionId?: string,
    unitsPerRegion?: any,
) {
    const usersPrDay: any = useMemo(() => {
        const usersPrDayMap = {};

        const usersPrDayPrUnit = {};
        getDates(dateFrom, dateTo).forEach((date) => {
            usersPrDayMap[date.toISOString().substring(0, 10)] = 0;
            usersPrDayPrUnit[date.toISOString().substring(0, 10)] = {};
        });

        authUsers?.users.forEach((user: AuthUser) => {
            const i = new Date(user.metadata.creationTime).toISOString().substring(0, 10);

            if (new Date(user.metadata.creationTime) > new Date(Date.now() - 1000*365 * 24 * 60 * 60 * 1000)) {

                const registeredEmployee = usersById && usersById[user.phoneNumber?.replace("+", "")];

                if (usersById && registeredEmployee) {
                    if (!regionId) {
                        usersPrDayMap[i] = (usersPrDayMap[i] || 0) + 1;
                        usersPrDayPrUnit[i][registeredEmployee.unit] =
                            usersPrDayPrUnit[i][registeredEmployee.unit] + 1 || 1;
                    } else if (
                        regionId &&
                        unitsPerRegion &&
                        unitsPerRegion.filter((unit) => usersById[user.phoneNumber?.replace("+", "")].unit === unit.id)
                            ?.length > 0
                    ) {
                        usersPrDayMap[i] = (usersPrDayMap[i] || 0) + 1;
                        usersPrDayPrUnit[i][registeredEmployee.unit] =
                            usersPrDayPrUnit[i][registeredEmployee.unit] + 1 || 1;
                    }
                }
            }
        });

        return { usersPrDayCount: usersPrDayMap, usersPrDayPrUnit };
    }, [authUsers, usersById, dbUsers]);
    return usersPrDay;
}

const RegionTable = (props: RegionTableProps) => {
    const { authUsers, usersByUserId } = useRegisteredUsers();
    const { usersById, users: dbUsers } = useSWRUsers();

    type Series = {
        label: string;
        data: [];
    };

    const primaryAxis = useMemo(
        (): AxisOptions<{ date: string; numberOfUsers: number }> => ({
            getValue: (datum) => datum.date,
            scaleType: "localTime",
        }),
        [],
    );

    const secondaryAxes = useMemo(
        (): AxisOptions<{ date: string; numberOfUsers: number }>[] => [
            {
                getValue: (registrations) => {
                    return registrations.numberOfUsers || 0;
                },
                elementType: "line",
            },
        ],
        [],
    );

    const ExpandedRegionTableErrorBoundary = ({ data }) => {
        return (
            <ErrorBoundary fallbackRender={(err) => <>{err.error.toString()}</>}>
                <ExpandedRegionTable data={data} />
            </ErrorBoundary>
        );
    };

    const ExpandedRegionTable = ({ data }) => {
        const { unitsAsList } = useUnits();
        const { users: employees } = useSWRUsers();

        const unitsInRegion = useMemo(() => {
            return unitsAsList?.filter((unit) => unit.region === data.id);
        }, [unitsAsList]);

        //const unitsPerRegion = useUnitsByRegion(data.id)

        const { usersPrDayCount: usersPrDay, usersPrDayPrUnit } = useUserPrDayLastNDays(
            authUsers,
            usersById,
            false,
            dbUsers,
            new Date(2024, 1, 1),
            new Date(Date.now()),
            data.id,
            unitsInRegion,
        );

        const userPrDayTotal = useMemo(() => {
            const _usersPrDayTotal = {};
            Object.entries(usersPrDay)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .reduce((previousValue, currentValue, currentIndex, array) => {
                    const total = previousValue + currentValue[1];
                    _usersPrDayTotal[currentValue[0]] = total;
                    return total;
                }, 0);
            return _usersPrDayTotal;
        }, [usersPrDay]);

        const userPrDayPerUnitTotal = useMemo(() => {
            const _usersPrDayPrUnitTotal = {};
            if (!usersPrDayPrUnit) return {};
            Object.entries(usersPrDayPrUnit)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .reduce((aggregateOfTotals, [currentDate, currentUnits]) => {
                    const totals = {};
                    unitsInRegion.forEach((unit) => {
                        totals[unit.id] =
                            (totals[unit.id] || 0) + (aggregateOfTotals[unit.id] || 0) + (currentUnits[unit.id] || 0);
                    });
                    _usersPrDayPrUnitTotal[currentDate] = totals;
                    return totals;
                }, {});

            return _usersPrDayPrUnitTotal;
        }, [usersPrDayPrUnit, unitsInRegion]);

        const [isPercentage, setIsPercentage] = useState(false);
        const [chartData, setChartData] = useState<Series[] | undefined>(undefined);

        useEffect(() => {
            if (unitsAsList && userPrDayTotal && usersPrDay && userPrDayPerUnitTotal && !isEmpty(usersPrDay)) {
                if (!isPercentage) {
                    setChartData([
                        {
                            label: "Brukerregistreringer totalt",
                            data:
                                Object.keys(usersPrDay).map((key) => {
                                    return {
                                        date: new Date(Date.parse(key)),
                                        numberOfUsers: userPrDayTotal[key],
                                    };
                                }) || [],
                        },
                        ...unitsInRegion.map((unit) => {
                            return {
                                label: unit.name || "N/A",
                                data:
                                    Object.keys(usersPrDay)?.map((key) => {
                                        return {
                                            date: new Date(Date.parse(key)),
                                            numberOfUsers:
                                                (userPrDayPerUnitTotal[key] && userPrDayPerUnitTotal[key][unit.id]) ||
                                                0,
                                        };
                                    }) || [],
                            };
                        }),
                    ]);
                } else {
                    setChartData([
                        {
                            label: "Brukerregistreringer totalt %",
                            data:
                                Object.keys(usersPrDay).map((key) => {
                                    return {
                                        date: new Date(Date.parse(key)),
                                        numberOfUsers:
                                            (userPrDayTotal[key] /
                                                employees.filter((employee) => {
                                                    return unitsInRegion.find((unit) => unit.id === employee.unit);
                                                }).length) *
                                            100,
                                    };
                                }) || [],
                        },
                        ...unitsInRegion.map((unit) => {
                            return {
                                label: unit.name || "N/A",
                                data:
                                    Object.keys(usersPrDay).map((key) => {
                                        return {
                                            date: new Date(Date.parse(key)),
                                            numberOfUsers:
                                                (((userPrDayPerUnitTotal[key] && userPrDayPerUnitTotal[key][unit.id]) ||
                                                    0) /
                                                    employees?.filter((employee) => employee.unit === unit.id)
                                                        ?.length) *
                                                100,
                                        };
                                    }) || [],
                            };
                        }),
                    ]);
                }
            }
        }, [isPercentage, unitsAsList, userPrDayTotal, usersPrDay]);

        if (!dbUsers || !authUsers || !unitsAsList || !props.regions) {
            return (
                <LoadingApotekData
                    regions={props.regions}
                    units={unitsAsList}
                    authUsers={authUsers}
                    dbUsers={dbUsers}
                />
            );
        }

        return (
            <div className={"relative w-full h-[30vw] bg-blue-50 p-6 my-6"}>
                <div className={"w-full relative h-full"}>
                    <ErrorBoundary
                        fallbackRender={(errorData) => {
                            return (
                                <>
                                    Error:
                                    <br />
                                    {errorData.error.toString()}
                                </>
                            );
                        }}
                    >
                        <Toggle
                            label={isPercentage ? "Se antall" : "Se prosenter"}
                            checked={isPercentage}
                            onChange={() => {
                                setIsPercentage(!isPercentage);
                            }}
                        />
                        {authUsers && chartData && chartData?.length > 0 && (
                            <Chart
                                options={{
                                    data: chartData,
                                    primaryAxis,
                                    secondaryAxes,
                                }}
                            />
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        );
    };

    const columns = [
        {
            name: "Overenhet / Region",
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => (
                <Link to={"/admin/regions/" + row.id} className={"underline"}>
                    {row.name || "N/A"}
                </Link>
            ),
        },
        {
            name: "Antall ansatte",
            selector: (row) => row.users?.length,
            sortable: true,
        },
        {
            name: "Antall registrerte",
            selector: (row) => row.users?.filter((user) => user.registeredUser)?.length,
            sortable: true,
        },
        {
            name: "Prosent registerte",
            selector: (row) => {
                return (row.users?.filter((user) => user.registeredUser)?.length / row.users?.length) * 100 || 0;
            },
            sortable: true,
            cell: (row) =>
                ((row.users?.filter((user) => user.registeredUser)?.length / row.users?.length) * 100 || 0).toFixed(1) +
                "%",
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={props.regions}
            persistTableHead
            pagination
            expandableRows
            expandableRowsComponent={ExpandedRegionTableErrorBoundary}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[25, 50]}
            progressPending={props.progressPending}
        />
    );
};

export function useUsersPerRegion() {
    const { regionsAsList } = useRegions();
    const { unitsAsList } = useUnits();
    const usersPerUnit = useUsersPerUnit();

    const usersPerRegion = useMemo(() => {
        return regionsAsList?.map((region) => {
            return {
                users: unitsAsList
                    ?.filter((unit) => unit.region === region.id)
                    .map((unit) => {
                        return usersPerUnit[unit.id];
                    })
                    .reduce((acc, curr) => {
                        return [...acc, ...curr.users];
                    }, []),
                ...region,
            };
        });
    }, [usersPerUnit, unitsAsList, regionsAsList]);

    return { regionsAsList, usersPerRegion };
}

const RegionsView = () => {
    const { regionsAsList, usersPerRegion } = useUsersPerRegion();
    const [isCreateNewRegion, setIsCreateNewRegion] = useState<boolean>(false);

    if (!regionsAsList) {
        return <div>loading...</div>;
    }

    const CreateForm = ({ visible, cancelClick }) => {
        const [name, setName] = useState("");
        const [displayMessage, setDisplayMessage] = useState("");

        const save = () => {
            if (name) {
                createRegion({ name: name });
            } else {
                setDisplayMessage("Navnet kan ikke være tomt");
            }
        };

        return (
            <Modal visible={visible}>
                <label htmlFor="countries" className="block mb-2 text-sm font-medium">
                    Velg navn
                </label>
                <input
                    value={name}
                    placeholder={"Navn på region"}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}
                />
                {displayMessage && <div className={"w-full p-3 bg-red-600 text-white"}>{displayMessage}</div>}
                <div className={"ml-auto mt-auto flex flex-row"}>
                    <Save onClick={save} />
                    <Cancel onClick={cancelClick} />
                </div>
            </Modal>
        );
    };

    const CreateRegionButton = () => {
        return (
            <Button
                className={"ml-4"}
                label={"Legg til region"}
                onClick={() => {
                    setIsCreateNewRegion(true);
                }}
            />
        );
    };

    return (
        <div>
            <CreateForm
                visible={isCreateNewRegion}
                cancelClick={() => {
                    setIsCreateNewRegion(false);
                }}
            />
            <h1 className={"text-2xl"}>Regioner og avdelinger</h1>
            <div className={"flex"}>
                <CreateRegionButton />
            </div>
            <RegionTable regions={usersPerRegion} progressPending={!regionsAsList} usersPerRegion={usersPerRegion} />
        </div>
    );
};

export default RegionsView;
