import { ComponentProps, MouseEventHandler, PropsWithChildren } from "react";

interface Props extends ComponentProps<"button"> {
    onClick: MouseEventHandler<HTMLButtonElement>;
}
export const Button = ({ className = "", children, ...props }: PropsWithChildren<Props>) => {
    return (
        <button className={`px-4 py-2 rounded-md ${className}`} {...props}>
            {children}
        </button>
    );
};
