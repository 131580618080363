import { FunctionComponent, PropsWithChildren, Suspense } from "react";
import { AuthProvider, useFirebaseApp, FirestoreProvider, StorageProvider } from "reactfire";
import { getAuth } from "firebase/auth"; // Firebase v9+
import { getFirestore } from "firebase/firestore"; // Firebase v9+
import { getStorage } from "firebase/storage";

export const FirebaseService: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const app = useFirebaseApp();
    const firestore = getFirestore(app);
    const storage = getStorage(app);

    const auth = getAuth(app);

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestore}>
                <StorageProvider sdk={storage}>
                    <Suspense>{children}</Suspense>
                </StorageProvider>
            </FirestoreProvider>
        </AuthProvider>
    );
};
