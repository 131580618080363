import { useCollection } from "@tatsuokaniwa/swr-firestore";

export const useLocations = () => {
    const { data: locations, ...rest } = useCollection<{ name: string }>({
        path: "locations",
    });

    return {
        locationsAsList: locations,
        locations: locations?.reduce(function (map, obj) {
            map[obj.id] = obj.name;
            return map;
        }, {}),
        ...rest,
    };
};
