import { useAuth } from "reactfire";
import useSWR from "swr";
import { useMemo } from "react";
import { useSWRUsers } from "../../../hooks/admin/useUsers";
import { useUnits } from "./useUnits";

interface RegistredUser {
    uid: string;
    emailVerified: boolean;
    phoneNumber: string;
    disabled: boolean;
    metadata: {
        lastSignInTime: string;
        creationTime: string;
        lastRefreshTime: string;
    };
    providerData: Array<{
        uid: string;
        providerId: string;
        phoneNumber: string;
    }>;
}

export const useRegisteredUsers = () => {
    const auth = useAuth();

    const { data: authUsers } = useSWR(
        "authUsers",
        async () => {
            const idTokenResult = await auth?.currentUser?.getIdTokenResult();
            const res = await fetch("https://getallusers-o47vzlec7q-uc.a.run.app", {
                headers: new Headers({ Authorization: "Bearer " + idTokenResult?.token }),
            });
            const data: { users: Array<RegistredUser> } = await res.json();
            return data;
        },
        {
            revalidateOnFocus: false,
            revalidateOnBlur: false,
        },
    );

    const usersByUserId = useMemo(() => {
        //return authUsers?.users.map(user => user.uid)
        if (!authUsers) return {};
        return Object.fromEntries(authUsers?.users.map((user) => [user.uid, user]));
    }, [authUsers]);

    const usersByPhoneNumber = useMemo(() => {
        //return authUsers?.users.map(user => user.uid)
        if (!authUsers) return {};
        return Object.fromEntries(authUsers?.users.map((user) => [user.phoneNumber?.replace("+", ""), user]));
    }, [authUsers]);
    return { authUsers, usersByUserId, usersByPhoneNumber };
};

export const useUsersPerUnit = () => {
    const { usersByPhoneNumber } = useRegisteredUsers();

    const { unitsAsList: units } = useUnits();
    const usersPerUnit = {};
    units?.forEach((unit) => {
        usersPerUnit[unit.id] = { users: [] };
    });

    const { users } = useSWRUsers();
    users?.forEach((user) => {
        if (user) {
            usersPerUnit[user.unit]?.users.push({
                userId: user.id,
                uid: usersByPhoneNumber && usersByPhoneNumber[user.id]?.uid,
                registeredUser: usersByPhoneNumber[user.id],
                ...user,
            });
        }
    });

    return usersPerUnit;
};

export const useRegisteredUserByPhoneNumber = (phoneNumber: string) => {
    const { usersByPhoneNumber } = useRegisteredUsers();

    return usersByPhoneNumber[phoneNumber];
};
