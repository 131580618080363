import { useCollection } from "@tatsuokaniwa/swr-firestore";
import { doc, getFirestore } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

type Unit = {
    name: string;
    region: string;
    area: string;
};

export const useUnits = () => {
    const { data, ...rest } = useCollection<{ name: string; region: string; area: string; location: string }>(
        {
            path: "units",
        },
        { revalidateOnFocus: false, dedupingInterval: 10000 },
    );

    const indexedUnits = (data ?? []).reduce<Record<string, Unit>>((map, obj) => {
        map[obj.id] = { name: obj.name, region: obj.region, area: obj.area };
        return map;
    }, {});

    return {
        unitsAsList: data,
        units: indexedUnits,
        ...rest,
    };
};

export const useUnit = (unitId: string) => {
    const db = getFirestore();
    // [value, loading, error]
    return useDocument(doc(db, "units", unitId), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });
};

export const useUnitsByRegion = (regionId: string) => {
    const { unitsAsList } = useUnits();
    return unitsAsList?.filter((unit) => unit.region === regionId) || [];
};
