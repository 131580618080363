import { Link } from "react-router-dom";
import { useRegisteredUsers, useUsersPerUnit } from "./components/useRegisteredUsers";
import { useRegions } from "./components/useRegions";
import { memo, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AxisOptions, Chart } from "react-charts";
import { ErrorBoundary } from "react-error-boundary";
import { LoadingApotekData } from "./LoadingApotekData";
import { useSWRUsers } from "../../hooks/admin/useUsers";
import { useUnits, useUnitsByRegion } from "./components/useUnits";
import { AuthUser, getDates } from "./AdminDefaultView";
import { BlockingData } from "swr/_internal";
import { DocumentData } from "@tatsuokaniwa/swr-firestore";
import { isEmpty } from "lodash";
import Toggle from "./components/Toggle";
import { useUserPrDayLastNDays } from "./RegionsView";
import { prop } from "react-data-table-component/dist/DataTable/util";
import { FilterComponent } from "./UsersView";
import { Cancel, DropdownRegion, EditForm, Modal, Save } from "./UnitView";
import { c } from "vite/dist/node/types.d-aGj9QkWt";
import { v4 as uuidv4 } from "uuid";

interface UnitsTableProps {
    units?: unknown[];
    progressPending?: boolean;
    usersPerUnit?: {};
}

export function Button({ label, onClick, className, ...rest }) {
    return (
        <div
            className={
                "rounded bg-green-500 text-white py-1.5 px-3 ml-auto hover:cursor-pointer hover:bg-green-700 " +
                className
            }
            onClick={onClick}
            {...rest}
        >
            {label}
        </div>
    );
}

const UnitsTable = (props: UnitsTableProps) => {
    const { authUsers, usersByUserId } = useRegisteredUsers();
    const { usersById, users: dbUsers } = useSWRUsers();
    const { regions, regionsAsList } = useRegions();

    const rows = useMemo(() => {
        return props.units.map((unit) => {
            return { ...unit, ...props.usersPerUnit[unit.id] };
        });
    }, [props.units, props.usersPerUnit]);

    type Series = {
        label: string;
        data: [];
    };

    const primaryAxis = useMemo(
        (): AxisOptions<{ date: string; numberOfUsers: number }> => ({
            getValue: (datum) => datum.date,
            scaleType: "localTime",
        }),
        [],
    );

    const secondaryAxes = useMemo(
        (): AxisOptions<{ date: string; numberOfUsers: number }>[] => [
            {
                getValue: (registrations) => {
                    return registrations.numberOfUsers || 0;
                },
                elementType: "line",
            },
        ],
        [],
    );

    const ExpandedRegionTableErrorBoundary = ({ data }) => {
        return (
            <ErrorBoundary fallbackRender={(err) => <>{err.error.toString()}</>}>
                <ExpandedRegionTable data={data} />
            </ErrorBoundary>
        );
    };

    const ExpandedRegionTable = ({ data }) => {
        const { unitsAsList } = useUnits();
        //const {users: employees} = useSWRUsers()

        const { usersPrDayCount: usersPrDay, usersPrDayPrUnit } = useUserPrDayLastNDays(
            authUsers,
            usersById,
            false,
            dbUsers,
            new Date(2024, 1, 1),
            new Date(Date.now()),
            undefined,
            unitsAsList,
        );

        const userPrDayTotal = useMemo(() => {
            const _usersPrDayTotal = {};
            Object.entries(usersPrDay)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .reduce((previousValue, currentValue, currentIndex, array) => {
                    const total = previousValue + currentValue[1];
                    _usersPrDayTotal[currentValue[0]] = total;
                    return total;
                }, 0);
            return _usersPrDayTotal;
        }, [usersPrDay]);

        const userPrDayPerUnitTotal = useMemo(() => {
            const _usersPrDayPrUnitTotal = {};
            if (!usersPrDayPrUnit) return {};
            Object.entries(usersPrDayPrUnit)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .reduce((aggregateOfTotals, [currentDate, currentUnits]) => {
                    const totals = {};
                    unitsAsList.forEach((unit) => {
                        totals[unit.id] =
                            (totals[unit.id] || 0) + (aggregateOfTotals[unit.id] || 0) + (currentUnits[unit.id] || 0);
                    });
                    _usersPrDayPrUnitTotal[currentDate] = totals;
                    return totals;
                }, {});

            return _usersPrDayPrUnitTotal;
        }, [usersPrDayPrUnit, unitsAsList]);

        const [isPercentage, setIsPercentage] = useState(false);
        const [chartData, setChartData] = useState<Series[] | undefined>(undefined);

        useEffect(() => {
            console.log("rerender units");
        }, [unitsAsList]);
        useEffect(() => {
            console.log("rerender userPrDayTotal");
        }, [userPrDayTotal]);
        useEffect(() => {
            console.log("rerender usersPrDay");
        }, [usersPrDay]);
        useEffect(() => {
            console.log("rerender userPrDayPerUnitTotal");
        }, [userPrDayPerUnitTotal]);
        useEffect(() => {
            if (unitsAsList && userPrDayTotal && usersPrDay && userPrDayPerUnitTotal && !isEmpty(usersPrDay)) {
                setChartData([
                    {
                        label: "Brukerregistreringer totalt",
                        data:
                            Object.keys(usersPrDay).map((key) => {
                                return {
                                    date: new Date(Date.parse(key)),
                                    numberOfUsers: userPrDayPerUnitTotal && userPrDayPerUnitTotal[key][data.id],
                                };
                            }) || [],
                    },
                ]);
            }
        }, [isPercentage, unitsAsList, userPrDayTotal, usersPrDay]);

        if (!dbUsers || !authUsers || !unitsAsList || !props.usersPerUnit) {
            return (
                <LoadingApotekData
                    regions={regions}
                    units={unitsAsList}
                    authUsers={authUsers}
                    dbUsers={dbUsers.length > 4000}
                />
            );
        }

        return (
            <div className={"relative w-full h-[30vw] bg-blue-50 p-6 my-6"}>
                <div className={"w-full relative h-full"}>
                    <ErrorBoundary
                        fallbackRender={(errorData) => {
                            return (
                                <>
                                    Error:
                                    <br />
                                    {errorData.error.toString()}
                                </>
                            );
                        }}
                    >
                        {/*<Toggle label={isPercentage ? "Se antall" : "Se prosenter"} checked={isPercentage} onChange={() => {
                    setIsPercentage(!isPercentage)
                }}/>*/}
                        {authUsers && chartData && chartData?.length > 0 && (
                            <Chart
                                options={{
                                    data: chartData,
                                    primaryAxis,
                                    secondaryAxes,
                                }}
                            />
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        );
    };

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [isDisplayCreateModal, setIsDisplayCreateModal] = useState(false);
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <div className={"flex flex-row w-full"}>
                <FilterComponent
                    onFilter={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
                <Button
                    className={"ml-4"}
                    label={"Legg til enhet"}
                    onClick={() => {
                        setIsDisplayCreateModal(true);
                    }}
                />
            </div>
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "Enhet / Apotek",
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => (
                <Link to={"/admin/units/" + row.id} className={"underline"}>
                    {row.name || "N/A"}
                </Link>
            ),
        },
        {
            name: "Overenhet / Region",
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => (
                <Link to={"/admin/regions/" + row.region} className={"underline"}>
                    {(regions && regions[row.region]) || "N/A"}
                </Link>
            ),
        },
        {
            name: "Antall ansatte",
            selector: (row) => row.users?.length,
            sortable: true,
        },
        {
            name: "Antall registrerte",
            selector: (row) => row.users?.filter((user) => user.registeredUser)?.length,
            sortable: true,
        },
        {
            name: "Prosent registerte",
            selector: (row) => {
                return (row.users?.filter((user) => user.registeredUser)?.length / row.users?.length) * 100 || 0;
            },
            sortable: true,
            cell: (row) =>
                ((row.users?.filter((user) => user.registeredUser)?.length / row.users?.length) * 100 || 0).toFixed(1) +
                "%",
        },
    ];

    const CreateForm = ({ regions, visible, cancelClick }) => {
        const [name, setName] = useState("");

        return (
            <Modal visible={visible}>
                <label htmlFor="countries" className="block mb-2 text-sm font-medium">
                    Velg navn
                </label>
                <input
                    value={name}
                    placeholder={"Navn på enhet"}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}
                />
                <DropdownRegion
                    regions={regions}
                    onChange={(e) => {
                        console.log(e);
                    }}
                />
                <div className={"ml-auto mt-auto flex flex-row"}>
                    <Save />
                    <Cancel onClick={cancelClick} />
                </div>
            </Modal>
        );
    };

    return (
        <>
            <EditForm
                unit={{ name: "", region: "" }}
                unitId={uuidv4()}
                regions={regionsAsList}
                visible={isDisplayCreateModal}
                cancelClick={() => {
                    setIsDisplayCreateModal(false);
                }}
            />
            <DataTable
                columns={columns}
                data={rows.filter((item) => {
                    return item.name.toLowerCase().includes(filterText.toLowerCase());
                })}
                persistTableHead
                pagination
                expandableRows
                expandableRowsComponent={ExpandedRegionTableErrorBoundary}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 1000]}
                progressPending={props.progressPending}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
            />
        </>
    );
};

/*
export function useUsersPerRegion() {
    const {regionsAsList} = useRegions();
    const {unitsAsList} = useUnits()
    const usersPerUnit = useUsersPerUnit()

    const usersPerRegion = useMemo(() => {
        return regionsAsList?.map((region) => {
            return {
                users: unitsAsList?.filter(unit => unit.region === region.id)
                    .map((unit) => {
                        return usersPerUnit[unit.id]
                    }).reduce((acc, curr) => {
                        return [...acc, ...curr.users]
                    }, []),
                ...region
            }

        })
    }, [usersPerUnit, unitsAsList, regionsAsList])

    return {regionsAsList, usersPerRegion};
}*/

const UnitsView = () => {
    const { regions } = useRegions();
    const { unitsAsList } = useUnits();
    const usersPerUnit = useUsersPerUnit();

    if (!usersPerUnit || !regions || !usersPerUnit || !unitsAsList) {
        return (
            <LoadingApotekData regions={regions} units={unitsAsList} authUsers={usersPerUnit} dbUsers={usersPerUnit} />
        );
    }

    return (
        <div>
            <h1 className={"text-2xl mb-10"}>Apotek og team</h1>
            <UnitsTable
                units={unitsAsList}
                progressPending={!unitsAsList}
                regions={regions}
                usersPerUnit={usersPerUnit}
            />
        </div>
    );
};

export default UnitsView;
