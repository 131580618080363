import { ReactNode } from "react";

interface LoadingViewProps {
    description?: string | ReactNode;
}

export const LoadingView = ({ description }: LoadingViewProps) => {
    return (
        <div className={"flex align-middle items-center h-full w-full flex-col"}>
            <svg
                className={"mx-auto h-12 animate-flicker opacity-80 mt-[20vh]"}
                viewBox="0 0 24 24"
                fill={"green"}
                xmlns="http://www.w3.org/2000/svg"
            >
                <title />
                <path d="M23.911,6.593A.986.986,0,0,0,22.647,6a3.185,3.185,0,0,1-.318.088.248.248,0,0,1-.236-.066L17.955,1.879a.249.249,0,0,1-.063-.246c.03-.1.061-.207.1-.309A.99.99,0,0,0,16.127.655a8.831,8.831,0,0,0,.194,6.484,6.847,6.847,0,0,1,.288,4.465.251.251,0,0,1-.419.111L12.263,7.788a.25.25,0,0,1,.111-.418,7.05,7.05,0,0,1,1.686-.245.989.989,0,0,0,.969-1.009,1.019,1.019,0,0,0-1.009-.969A9.148,9.148,0,0,0,7.788,7.8,9.012,9.012,0,0,0,5.83,17.631a6.891,6.891,0,0,1,.517,2.017L4.024,17.325a.98.98,0,0,0-.7-1.7,10.5,10.5,0,0,0-2.664.512.989.989,0,0,0,.333,1.921A1,1,0,0,0,1.323,18c.1-.037.21-.062.316-.089a.25.25,0,0,1,.237.066l4.139,4.139a.251.251,0,0,1,.063.247c-.03.106-.063.211-.1.316a.988.988,0,1,0,1.86.67,8.825,8.825,0,0,0-.19-6.493,6.835,6.835,0,0,1-.288-4.465.252.252,0,0,1,.177-.176.248.248,0,0,1,.242.064L11.706,16.2a.25.25,0,0,1-.111.418,6.982,6.982,0,0,1-1.7.245.99.99,0,0,0,.018,1.979H9.93a9.158,9.158,0,0,0,6.251-2.653,9.019,9.019,0,0,0,1.546-2.084v0a8.945,8.945,0,0,0,.411-7.747,6.877,6.877,0,0,1-.517-2.016l2.3,2.3a.976.976,0,0,0-.354.767.991.991,0,0,0,.988.962h.029a10.545,10.545,0,0,0,2.728-.514A.989.989,0,0,0,23.911,6.593Zm-9.876,8.841a.248.248,0,0,1-.325-.024L8.421,10.121A7.038,7.038,0,0,1,9.187,9.2a6.788,6.788,0,0,1,.746-.64.251.251,0,0,1,.326.024L15.4,13.72a.251.251,0,0,1,.024.326,6.312,6.312,0,0,1-1.387,1.388Z" />
            </svg>
            <div className="mx-auto mt-10">{description}</div>
        </div>
    );
};
