import { useCollection } from "@tatsuokaniwa/swr-firestore";

export const useRegions = () => {
    const { data, ...rest } = useCollection<{ name: string; area: string; location: string }>({
        path: "region",
    });

    const regions = data ?? [];

    const indexedRegions = regions.reduce<Record<string, string>>((map, obj) => {
        map[obj.id] = obj.name;
        return map;
    }, {});

    return {
        regionsAsList: regions,
        regions: indexedRegions,
        ...rest,
    };
};
